<template>
  <footer
    class="mille1pains-footer flex justify-center px-4 text-gray-100 "
    :class="footerOpen ? 'opened' : 'closed'"
  >
    <button
      v-click-outside="close"
      class="trigger-footer"
      @click="footerOpen = !footerOpen"
    >
      <i
        v-if="footerOpen"
        class="bi bi-chevron-double-down"
      />
      <i
        v-else
        class="bi bi-chevron-double-up"
      />
    </button>
    <div class="container py-6">
      <h1 class="text-center text-lg font-bold lg:text-2xl">
        Vous cherchez à nous joindre ?
      </h1>

      <div class="flex justify-center mt-6">
        <div class="rounded-lg">
          <div class="flex flex-wrap justify-between md:flex-row">
            <a
              href="/contact"
              class="w-full m-1 px-5 py-4 text-sm orangeColor rounded-lg font-semibold uppercase lg:w-auto"
            >
              Contactez-moi
            </a>
          </div>
        </div>
      </div>

      <hr class="h-px mt-6 bg-gray-700 border-none">

      <div class="flex flex-col items-center justify-between mt-6 md:flex-row">
        <div>
          <a
            href="#"
            class="text-xl font-bold brand"
          >Mille et 1 pains</a>
        </div>
        <div class="flex mt-4 md:m-0">
          <div class="-mx-4">
            <router-link
              to="/"
              class="px-4 text-sm"
            >
              Accueil
            </router-link>
            <router-link
              to="/contact"
              class="px-4 text-sm"
            >
              Contact
            </router-link>
            <router-link
              to="/about"
              class="px-4 text-sm"
            >
              A propos
            </router-link>
            <router-link
              to="/Maps"
              class="px-4 text-sm"
            >
              Nous trouver
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import ClickOutside from 'vue-click-outside'
export default {
  name: 'Footer',
  directives: {
    ClickOutside
  },
  data () {
    return {
      footerOpen: false
    }
  },
  mounted () {
    this.popupItem = this.$el
  },
  methods: {
    close () {
      this.footerOpen = false
    }
  }
}
</script>
<style lang="scss">
.mille1pains-footer {
  position:absolute;
  bottom: 0;
  //height: 60vh;
  width: 100%;
  transition: all ease 0.5s;
  background-color: #2B2A28;
}

.orangeColor {
  background:  darken(#CD9128, 5%);
  color: lighten(#E8B75B, 10%);
  &:hover {
    color: white;
  }
}

.brand {
  color: #CD9128;
  font-family: 'Amatic SC', cursive;
}

.closed {
  transform: translateY(100%);
}

.opened {
  transform: translateY(0);
}

.trigger-footer {
  position: absolute;
  top: -50px;
  color: #2B2A28;
  font-size: 2em;
  animation: breathArrow 3s infinite;
  transform: translateY(-10px);
  &:active, &:focus {
    outline: 0;
  }
}

@media screen AND (max-width:769px) {
  .trigger-footer {
    display: none;
  }
}
@media screen and (max-height: 800px) {
  .trigger-footer {
    display: none;
  }
}

@keyframes breathArrow {
  50% {
    transform: translateY(0);
  }
}
</style>
