<template>
  <div class="get-contact flex flex-col space-x-5 justify-center md:justify-start z-10">
    <ul>
      <li>
        <a
          href="https://www.facebook.com/Mille-et-1-pains-718320738547645"
          target="_blank"
        >
          <i class="bi bi-facebook" />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/mille_et_1_pains/"
          target="_blank"
        >
          <i class="bi bi-instagram" />
        </a>
      </li>
      <!-- <li>
        <a
          href="#"
          target="_blank"
        >
          <i class="bi bi-twitter" />
        </a>
      </li> -->
      <li class="phoneContact">
        <a
          href="tel: +33476541159"
        >
          <i class="bi bi-telephone-fill" />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'MiniNav'

}

</script>
<style lang="scss">

.get-contact {
  position: fixed;
  display: flex;
  vertical-align: middle;
  height: 100%;
  width: 55px;
  left: 24px;
  ul {
    margin: auto;
    li {
      margin: 8px auto;
      cursor: pointer;
      a {
        font-size: 14px;
      }
    }
    .phoneContact {
      width: 34px;
      height: 34px;
      background-color: #797876;
      color: white;
      border-radius: 50%;
      display: flex;
      a {
        margin: auto;
      }
    }
  }
}

@media screen AND (max-width:769px) {
  .get-contact {
    display: none;
  }
}

</style>
