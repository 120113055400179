<template>
  <header class="flex flex-col items-center justify-center w-screen pt-10 px-5 pb-5 z-10">
    <nav
      class="navColor relative w-full max-w-screen-xl flex items-center px-6 h-16 rounded text-black"
      :class="headerOpac ? 'shadow-xl md:shadow-2xl' : ''"
      :style="headerOpac ? 'background:white' : ''"
    >
      <a href="/">
        <img
          class="h-10 md:h-16 "
          src="/img/logo.png"
          alt="logo"
        >
      </a>
      <div class="lg:ml-6 ml-2 lg:space-x-2 space-x-0 hidden md:flex">
        <router-link
          class="h-10 flex items-center lg:px-4 px-3 rounded font-medium hover:bg-gray-100"
          to="/"
        >
          Accueil
        </router-link>
        <router-link
          class="h-10 flex items-center lg:px-4 px-3 rounded font-medium hover:bg-gray-100"
          to="/contact"
        >
          Nous contacter
        </router-link>
        <router-link
          class="h-10 flex items-center lg:px-4 px-3 rounded font-medium hover:bg-gray-100"
          to="/about"
        >
          à propos
        </router-link>
      </div>
      <a
        :href="'tel:'+ contact.phone"
        class="flex items-center h-10 pl-2 pr-2 sm:pr-4 rounded bg-gray-100 ml-auto hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
      >
        <i class="h-6 bi bi-telephone-fill" />
        <span class="font-medium ml-1 leading-none hidden sm:block pl-2">{{ contact.phoneComputed }}</span>
      </a>
      <router-link
        class="flex items-center justify-center h-10 w-10 rounded ml-4 hover:bg-gray-200 focus:bg-gray-200"
        to="/Maps"
      >
        <i
          class="bi bi-geo-alt"
          style="font-size:1.5em"
        />
      </router-link>
      <div class="flex-col md:hidden ml-4">
        <button
          v-click-outside="close"
          class="menu-btn h-10 w-10 flex items-center justify-center rounded focus:bg-gray-200 focus:outline-none hover:bg-gray-200"
          @click="navOpen = !navOpen"
        >
          <svg
            class="w-7 h-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <!-- v-click-outside="close" -->
        <div
          v-show="navOpen"
          class="absolute flex flex-col w-full bg-white py-3 px-4 rounded-br rounded-bl right-0 shadow-lg"
        >
          <router-link
            class="h-10 flex items-center px-2 rounded font-medium hover:bg-gray-100"
            to="/"
          >
            Accueil
          </router-link>
          <router-link
            class="h-10 flex items-center px-2 rounded font-medium hover:bg-gray-100"
            to="/contact"
          >
            Nous contacter
          </router-link>
          <router-link
            class="h-10 flex items-center px-2 rounded font-medium hover:bg-gray-100"
            to="/about"
          >
            à propos
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import ClickOutside from 'vue-click-outside'
export default {
  directives: {
    ClickOutside
  },
  data () {
    return {
      navOpen: false,
      contact: {
        phone: '+33476541159',
        phoneComputed: '04 76 54 11 59'
      }
    }
  },
  computed: {
    headerOpac () {
      if (this.$route.name === 'Maps') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    $route (to, from) {
      this.navOpen = false
    }
  },
  mounted () {
    this.navBarResponsive()
    this.popupItem = this.$el
  },
  methods: {
    close () {
      this.navOpen = false
    },
    navBarResponsive () {
      // Get all "navbar-burger" elements
      var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach(function ($el) {
          $el.addEventListener('click', function () {
            // Get the "main-nav" element
            var $target = document.getElementById('main-nav')

            // Toggle the class on "main-nav"
            $target.classList.toggle('hidden')
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.myCustomFont {
  font-family: 'Amatic SC', cursive;
  color: #CD9128;
  font-size: 1.2em;
  b {
    font-family: cursive;
  }
}

.myLinks {
  &:hover {
    color:#CD9128;
  }
}

@media screen AND (max-width: 770px) {
  .navColor {
    background: white;
  }
}

</style>
