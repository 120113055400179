<template>
  <div class="main">
    <main class="main--mille1pains">
      <HeaderComponent />
      <MiniNav />
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </main>
    <FooterComponent />
  </div>
</template>
<script>
import HeaderComponent from '@/components/Header.vue'
import FooterComponent from '@/components/Footer.vue'
import MiniNav from '@/components/ContactNav.vue'
export default {
  name: 'Default',
  components: {
    FooterComponent,
    HeaderComponent,
    MiniNav
  }
}
</script>
<style lang="scss" scoped>
.main {
  position:relative;
  width: 100%;
  height: 100%;
  &--mille1pains {
    width: 100%;
    min-height: 100vh;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
